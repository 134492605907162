import { datadogRum } from '@datadog/browser-rum';

// https://app.datadoghq.com/rum/application/f16d3986-8f52-471e-bafa-6ae807d88a6c
export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();

    if (import.meta.dev) return;

    datadogRum.init({
        applicationId: 'f16d3986-8f52-471e-bafa-6ae807d88a6c',
        clientToken: 'pub09e69f091bf0431d0983f34121f9eeea',
        site: 'datadoghq.com',
        service: 'es-cms-articles',
        env: config.public.DEPLOY_ENV,
        version: config.public.VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackSessionAcrossSubdomains: true,
    });

    datadogRum.startSessionReplayRecording();
});
