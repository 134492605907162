import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArticleAccordion, LazyArticleBreadcrumbList, LazyArticleButton, LazyArticleCollapse, LazyArticleCtaBannerImage, LazyArticleCtaLeafBanner, LazyArticleDataGeoLinks, LazyArticleDataRichContent, LazyArticleDataTable, LazyArticleEeat, LazyArticleH2, LazyArticleImage, LazyArticleInfogram, LazyArticleLink, LazyArticlePage001, LazyArticleRelated, LazyArticleRichContent, LazyArticleTableOfContents, LazyArticleTakeAways, LazyArticleTips, LazyArticleUnhandledBlockFromWordpress, LazyArticleVideo, LazyDataRichContent, LazyGlobalCtaFixedUpperBanner, LazyLocalArticleEeatV2, LazyLocalArticlePage001, LazyArticleRecommended, LazyGlobalCtaBanner, LazyGlobalCtaBannerImage, LazyGlobalCtaButton, LazyGlobalCtaLeafBanner, LazyGlobalCtaLink, LazyGlobalEeatInfo, LazyGlobalEeatInfoSellingPoint, LazyGlobalStickyZipCta, LazyGlobalVerticalCta, LazyGlobalZipCodeForm, LazyPageImage, LazyReviewsIoCarousel, LazyRichDataTable, LazyRichDataTableCell, LazyRichDataTableColumn, LazyRichDataTableRow, LazySiteNavigation, LazySiteNavigationFooter } from '#components'
const lazyGlobalComponents = [
  ["ArticleAccordion", LazyArticleAccordion],
["ArticleBreadcrumbList", LazyArticleBreadcrumbList],
["ArticleButton", LazyArticleButton],
["ArticleCollapse", LazyArticleCollapse],
["ArticleCtaBannerImage", LazyArticleCtaBannerImage],
["ArticleCtaLeafBanner", LazyArticleCtaLeafBanner],
["ArticleDataGeoLinks", LazyArticleDataGeoLinks],
["ArticleDataRichContent", LazyArticleDataRichContent],
["ArticleDataTable", LazyArticleDataTable],
["ArticleEeat", LazyArticleEeat],
["ArticleH2", LazyArticleH2],
["ArticleImage", LazyArticleImage],
["ArticleInfogram", LazyArticleInfogram],
["ArticleLink", LazyArticleLink],
["ArticlePage001", LazyArticlePage001],
["ArticleRelated", LazyArticleRelated],
["ArticleRichContent", LazyArticleRichContent],
["ArticleTableOfContents", LazyArticleTableOfContents],
["ArticleTakeAways", LazyArticleTakeAways],
["ArticleTips", LazyArticleTips],
["ArticleUnhandledBlockFromWordpress", LazyArticleUnhandledBlockFromWordpress],
["ArticleVideo", LazyArticleVideo],
["DataRichContent", LazyDataRichContent],
["GlobalCtaFixedUpperBanner", LazyGlobalCtaFixedUpperBanner],
["LocalArticleEeatV2", LazyLocalArticleEeatV2],
["LocalArticlePage001", LazyLocalArticlePage001],
["ArticleRecommended", LazyArticleRecommended],
["GlobalCtaBanner", LazyGlobalCtaBanner],
["GlobalCtaBannerImage", LazyGlobalCtaBannerImage],
["GlobalCtaButton", LazyGlobalCtaButton],
["GlobalCtaLeafBanner", LazyGlobalCtaLeafBanner],
["GlobalCtaLink", LazyGlobalCtaLink],
["GlobalEeatInfo", LazyGlobalEeatInfo],
["GlobalEeatInfoSellingPoint", LazyGlobalEeatInfoSellingPoint],
["GlobalStickyZipCta", LazyGlobalStickyZipCta],
["GlobalVerticalCta", LazyGlobalVerticalCta],
["GlobalZipCodeForm", LazyGlobalZipCodeForm],
["PageImage", LazyPageImage],
["ReviewsIoCarousel", LazyReviewsIoCarousel],
["RichDataTable", LazyRichDataTable],
["RichDataTableCell", LazyRichDataTableCell],
["RichDataTableColumn", LazyRichDataTableColumn],
["RichDataTableRow", LazyRichDataTableRow],
["SiteNavigation", LazySiteNavigation],
["SiteNavigationFooter", LazySiteNavigationFooter],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
