import revive_payload_client_4sVQNw7RlN from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/es-cms-articles/es-cms-articles/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/home/runner/work/es-cms-articles/es-cms-articles/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_tbFNToZNim from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import sentry_client_VdOeuw25Mb from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/es-cms-articles/es-cms-articles/.nuxt/sentry-client-config.mjs";
import plugin_hBLWEPkA3b from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import i18n_Ug908EZNww from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import datadog_client_cfAAU11B0P from "/home/runner/work/es-cms-articles/es-cms-articles/plugins/datadog.client.ts";
import ssg_detect_3fHkBxLtv0 from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_tbFNToZNim,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_hBLWEPkA3b,
  i18n_Ug908EZNww,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  datadog_client_cfAAU11B0P,
  ssg_detect_3fHkBxLtv0
]